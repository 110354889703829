<template>
  <div class="cat-grid">
    <b-card
      v-for="(cat, index) in categories"
      :key="index"
      class="cat cursor-pointer mb-0"
      @click="navigateToCategory(cat)"
    >
      <div
        class="
          h-100
          d-flex
          flex-column
          justify-content-between
          align-items-center
        "
      >
        <p
          class="
            mb-2
            name
            text-wrap
            whitespace-normal
            w-100
            line-clamp-3
            truncate
            text-center text-lg
          "
        >
          {{ getCategoryNameInSpanish(cat) }}
        </p>
        <b-img :src="getCatImage(cat)" class="cat-img" />
      </div>
    </b-card>
  </div>
</template>

<script>
import _ from "underscore";
import Ripple from "vue-ripple-directive";
import { mapActions, mapMutations } from "vuex";

import booksImg from "@/assets/images/categories/book.png";
import groceriesImg from "@/assets/images/categories/groceries.png";
import hardwareStoreImg from "@/assets/images/categories/hardware_store.png";
import flowerShopImg from "@/assets/images/categories/flower_shop.png";
import tortillasImg from "@/assets/images/categories/tortillas.png";
import coffeeShopImg from "@/assets/images/categories/coffeeShop.png";
import clothesImg from "@/assets/images/categories/clothes.png";
import waterImg from "@/assets/images/categories/water.png";
import corpEdImg from "@/assets/images/categories/corporate_education.png";
import cleaningImg from "@/assets/images/categories/cleaning.png";

export default {
  directives: {
    Ripple,
  },
  watch: {
    // eslint-disable-next-line
    "filters.page": function () {},
  },

  data() {
    return {
      categories: null,
      userData: JSON.parse(localStorage.getItem("userData")),
    };
  },

  mounted() {
    this.fetchStoreTypes().then((response) => {
      this.categories = response;
    });
    this.emptyCart();
    this.setEmptyOnlineStores();
  },

  methods: {
    ...mapMutations("establishments", ["setEmptyOnlineStores"]),
    ...mapActions("pos", ["emptyCart"]),
    ...mapActions("stores", ["fetchStoreTypes"]),

    getCategoryNameInSpanish(name) {
      // Define a mapping of English to Spanish category names
      const categoryTranslations = {
        corner_shop: "Abarrotes",
        school_cafeteria: "Escuela",
        flower_store: "Florería",
        book_store: "Librería",
        hardware_store: "Ferretería",
        tortilleria: "Tortillería",
        coffee_shop: "Cafetería",
        clothing_store: "Ropa",
        water_purifier: "Agua purificada",
        corporate_education: "Cursos",
        cleaning_hardware: "Productos de limpieza",
        other: "Otras",
      };

      // Return the Spanish translation or the original name if not found
      return categoryTranslations[name] || name;
    },

    getCatImage(name) {
      // Define a mapping of English to Spanish category names
      const categoryImages = {
        corner_shop: groceriesImg,
        school_cafeteria: "",
        flower_store: flowerShopImg,
        book_store: booksImg,
        hardware_store: hardwareStoreImg,
        tortilleria: tortillasImg,
        coffee_shop: coffeeShopImg,
        clothing_store: clothesImg,
        water_purifier: waterImg,
        corporate_education: corpEdImg,
        cleaning_hardware: cleaningImg,
        other: "",
      };

      // Return the Spanish translation or the original name if not found
      return categoryImages[name] || name;
    },

    navigateToCategory(name) {
      const fromStoreId = this.$route.params.from_store_id
        ? this.$route.params.from_store_id
        : null;
      this.$router.push({
        name: "establishment-landing-page",
        params: {
          // from_store_id: fromStoreId,
          // store_type: name,
          slug: "marketplace",
        },
        query: {
          store_type: name,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.card-img-top {
  max-width: 225px;
}

.categories-list {
  display: flex;
  flex: 1 1 300px; // Allow items to grow to the width of the container, but don't shrink below 300px
  margin: 10px; // Adjust margin to create space between items
}
.cat-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  font-size: 18px;
  font-weight: 500;

  .cat {
    max-height: 200px;

    .cat-img {
      max-height: 5rem;
    }
  }

  @media (min-width: 350px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 640px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (min-width: 1536px) {
    grid-template-columns: repeat(8, 1fr);

    .cat {
      .cat-img {
        max-height: 6rem;
      }
    }
  }
}
</style>
